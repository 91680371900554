@import "@lifepowr/components/src/colors.scss";

.device-io-settings{
    height: 100%;
    display: flex;
    flex-direction: column;

    .device-io-settings__check-box-wrapper{
        padding-top: 0.3em;
        display: flex;
        flex-direction: column;
                
        .device-io-settings__check-box{
            display: block;
            position: relative;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            
            .device-io-settings__check-box__label{ 
                margin-left: 3em; 
                font-size: 0.9em;
                display:block;
                margin-top: -1.2em;
            }
            /* Hide the browser's default checkbox */
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
            
            /* Create a custom checkbox */
            .device-io-settings__check-box__checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #ccc;
                border-radius: 5px;
            }
            
            /* When the checkbox is checked, add a blue background */
            input:checked ~ .device-io-settings__check-box__checkmark {
                background-color: #0e5c5c;
            }
            
            /* Create the checkmark/indicator (hidden when not checked) */
            .device-io-settings__check-box__checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }
            
            /* Show the checkmark when checked */
            input:checked ~ .device-io-settings__check-box__checkmark:after { display: block; }
            
            /* Style the checkmark/indicator */
            .device-io-settings__check-box__checkmark:after {
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
    .device-io-settings__form-loader {
        width: 100%;
        // padding-top:9em;
        height: 5em;
        text-align: center;
        > .MuiCircularProgress-root  {
            color: $color-green-even-darkest;
        }
    }
    /* EMS drop down styles*/
    .device-io-settings__ems-wrapper
    {
       display: grid;
       grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
       grid-gap: 15px;

       .device-io-settings__mode-select {
          grid-column: span 3;
       }

       .device-io-settings__ap-mode {
          grid-column: span 3 / end;
          margin: 0;
       }

        .Mui-disabled {
            background-color: $color-gray-light;
        }
        .MuiFormControl-root {
            fieldset { border-color: transparent}
            width: 100%;

            > div {
                border: transparent;
                border-radius: 8px;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);            
                background-color: white;

                > div{
                    border-radius: 8px;
                }

                input {
                    padding-right: 32px;
                    height: 50px;
                    padding-left: 1em;
                    border-radius: 8px;
                }
            }
        }
    }
    /* wraps around the whole form*/
    .device-io-settings__form-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-top: 10px;

        form{ 
            /* top errors */
            .errors{ 
                margin-bottom: 2em;
                ul{
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);  
                    li { border: transparent; }
                    :nth-child( 1 ){ //first li
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    }
                    :last-child{ //last li
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }         
                }
            }
            /* errors by input */
            .field-error {
                width: 50%;
                ul{ 
                    padding-left: 1.3em;
                    width: 50%;
                    position: absolute;
                    top: 6.5em;
                    right: 0;
                    font-size: 12px;
                    list-style-type: none; 
                    color: #ec0f0f;
                }
            }

            // first form-group form parent wrapper 
            > .form-group {
                p { width: 100%; }   //all paragraphs
                // label, input {width: 43% } //all labels and inputs
                // how all fieldsets display their inner elements
                /* fieldset{   
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .field-integer, .field-number, .field-string {  flex: 0 1 49%;  } //side by side elements
                } */
                //clear default space added by framework
                .row { margin: 0; }
                .field-array{
                    margin: 0;
                    .field-array{
                        margin-top: 2em;
                        .array-item-list{ 
                            .array-item { flex: 0 1 99%; }
                        }       
                    }
                }
                .field-object { width: 100%; }

                //font style
                label{
                    font-size: 16px;
                    font-weight: bold;
                    text-align: left;
                    color: $color-black;
                }
                p {
                    font-size: 12px;
                    font-weight: normal;
                    color: $color-gray-dark;
                }

                //input styling 
                select { height: 55px; width: 49%; margin-bottom: 1em;}
                //for input positioning to the right 
                > .form-group {  position: relative; } 
                
                .field-number, .field-integer, .field-string {
                    position: relative;
                    border-radius: 8px;
                    padding: 1em;
                    background-color: $color-white;
                    // p { width: 50%;}
                }
                input {
                    /*position:absolute; top: 1em; right: 1em;*/
                    border: solid 1px $color-gray-light;
                    border-radius: 8px;
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);            
                }
    
                //data spinner removes side arrows to increment and decrease values not working i think but left here for reference 
                input::-webkit-inner-spin-button { opacity: 1; } 

                //styles all select boxes in form
                select {
                    border: transparent;
                    border-radius: 8px;
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
                }
                
                //for ARMA coefficients to be displayed. for some reason they are being displayed diferently from the other form elements 
                .panel {
                    .field-object{
                        width: 100%;
                        fieldset{
                            width: 100%;
                            .field-array{
                                width: 100%;
                                .array-item-list{
                                    width: 100%;
                                    .form-group{
                                        min-height: 6em;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    
    .device-io-settings{
        .device-io-settings__ems-wrapper {
            .device-io-settings__mode-select {
                grid-column: span 5;
            }

            .device-io-settings__ap-mode {
                grid-column: span 4 / end;
                margin: 0;
            }
        }

        /* .device-io-settings__form-wrapper {
            padding-top: 2em;
            margin-top: -7em;
        } */
        
        .device-io-settings__setting{
            height: 8em;
        }   
    }
}
// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    
    .device-io-settings{
        position: relative;
        .device-io-settings__setting{
            height: 6em;
        }   
    }
}