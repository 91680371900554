.easee-chargers-step-container{
	.hidden {
		display: none;
	}

	.easee-chargers-step-list{
		width: 95%;
		min-width: 120px;
	}

	.easee-chargers-input-form{
		min-width: 150px;
	}

	.easee-chargers-step-nochargers{
		width: 100%
	}

	display:grid;
	grid-template-rows: 2fr 1fr;
	justify-items: center;
	justify-content: center;
}

.easee-chargers-list-new{
	display:grid;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	justify-items: center;
	justify-content: center;
}