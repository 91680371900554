@import "@lifepowr/components/src/colors.scss";

.device-nav-menu-option {
    font-weight: bold;
    color: $color-black;
    cursor: pointer;
    text-align: center;
    height: 2.5em;
    .device-nav-menu-option__underline {
        margin: auto;
        width: 35%;
        display: block;
        margin-top: 8px;
        border-radius: 15px;
        background: $color-yellow-light-main;
        height: 4px;
    }
    .device-nav-menu-option__gray {
        color: $color-gray-dark;
        font-weight: normal;
    }

    .device-nav-menu-option__underline {
        width: 100%;
        margin-top: 0.4em;
    }
}
.disabled {
    cursor: default;
}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
