.wallbox-account-cards{
	display:  grid;
	grid : auto / repeat(auto-fit, minmax(50px, 150px));
	/*grid-template-columns: repeat(3, 1fr);*/
	justify-content : center;
	grid-gap : 20px;
	padding : 20px;
    overflow: auto;

    .wallbox-account-content{
		display: grid;
		grid-template-rows: 1fr minmax(25px, 30px);
		justify-content : center;

		.wallbox-account-content-icon{
			width: 3em;
			height: 3em;
		}
	}

	.wallbox-account-content-username{
		overflow-wrap: anywhere;
	}
}

.wallbox-account-login{
	display:  grid;
	grid: auto / repeat(auto-fit, minmax(50px, 150px));
	justify-content : center;
	grid-gap: 20px;
}

.wallbox-account-chargers{
	padding: 25px;
	/*display:  grid;
	grid: auto / repeat(auto-fit, minmax(100px, 200px));
	justify-content : center;
	grid-gap: 20px;

	.wallbox-media{
		max-height: 100%;
		max-width: 100%;
	}

	.wallbox-charger-check{
		position: relative;
		left: 80%;
	}*/
}

.wallbox-account-chargers-empty{
	text-align: center;
}

.wallbox-confirm {
	padding: 20px;

	.charger-list {
		display: grid;
		justify-content: center;
	}

	.wallbox-charger-list-item {
		padding-left: 35px;
	}
}

.wallbox-tab {
	display: grid;
	max-width: 100%;
	grid-template-columns: repeat(auto-fill, minmax(100px, 300px));
}

.wallbox-ocpp {
	display: grid;
	justify-items: center;
	align-items: center;

	/*.wallbox-ocpp-steps-innner{
	}*/

	.wallbox-ocpp-steps {
		overflow: auto;
	}

	.wallbox-ocpp-img {
		max-width: 70%;
	}

	.wallbox-gif-img {
		width: 100%;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
	.wallbox-ocpp {
		grid-template-columns: 1fr 1fr;
		
		.wallbox-ocpp-steps {
			grid-template-columns: 1fr minmax(300px, 500px);
			grid-template-rows: 1fr;
		}

		.wallbox-ocpp-confirm-wait {
			grid-column: 1 / 3;
		}
	}

	/*.wallbox-ocpp-steps-innner {

	}*/
}