@import "@lifepowr/components/src/colors.scss";

.device-chart-general-status {
    background-color: $color-white;
    border-radius: 4px;
    display: grid;
    grid-template-rows: fit-content(100px) 1fr;
    grid-gap: 10px;

    &__header {
        h1 {
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $color-black;
            padding-left: 15px;
        }
        .my-drop-down{
            width: 300px;
            padding-top: 0.8em;
            border-radius: 8px;
            padding-left: 15px;
        }
    }

    /* &__graph {
        height: 100%;
    } */

}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
