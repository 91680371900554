@import '@lifepowr/components/src/colors.scss';

.my-drop-down{
    position: relative;
    
    .FieldDropDown__placeholder{
        position: absolute;
        top: 40%;
        left: 10%;
        opacity: 0.5;
    }

    .Dropdown-root {
        height: 60px;
        
        .Dropdown-menu { padding-top: 0;}
        .Dropdown-control {
            border-radius: 8px;
            border: 0;
            height: 3.15em;
            padding-top: 1.4em;
            padding-left: 1em;
            font-size: 16px;
            box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.11);

            .Dropdown-arrow { top: 25px; }
        }
        .Dropdown-menu{
            border-radius: 8px;
            border: 0;
            box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.11);
            .Dropdown-option { background-color: $color-white; }
        }
    }
    
    .my-drop-down__label{
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $color-gray;
        text-transform: uppercase;

        position: absolute;
        top:0.5em;
        left: 1.1em;
        z-index: 2;
    }
}

.graph {
    .Dropdown-root {
        .Dropdown-control {
            border-radius: 8px;
            box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.11);
        }
    }
}

.no-label {
    .Dropdown-root {
        .Dropdown-control {
            padding-top: 0.6em;
            padding-left: 0.4em;
        }
    }
}