@import "@lifepowr/components/src/colors.scss";

.device-general-status {
    background-color: $color-green-lighest;
    display: grid;
    grid-template-rows: fit-content(100px) 1fr;

    .device-general-status__statistics {
        margin-bottom: 15px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 0px 15px;

        .device-general-status__statistics__div {
            background-color: $color-white;
            width: 100%;
            padding-left: 15px;
        }
    }
}

.inlineIcon {
    height: 24px;
    margin: 0 1em;
}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}