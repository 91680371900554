
@import '@lifepowr/components/src/colors.scss';

.loading-more{
    width: 100%;
    padding-top: 3em;
    margin-bottom: 3em;

    > div { width: 100%; }
    
}