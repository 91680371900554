@import "@lifepowr/components/src/colors.scss";

.lost-page {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 40px 2fr 1fr;
	justify-items: center;

	img {
		max-width: 25%;
	}
}