@import "@lifepowr/components/src/colors.scss";

.devices-page{
    height: 100%;
    background-color: $color-gray-lightest;
    display: grid;
    grid-template-rows: 1fr 9fr;
    grid-gap: 10px;

    .single-device-link{
        .link-button{
            width: 100%;
        }

        width: 100%;
    }

    .devices-page__titleNfilters{    
        padding-top: 2%;
        display: flex;
        flex-direction: column;

        .devices-page__title {
            font-size: 34px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $color-black;
            margin-bottom: 1em;
        }
    }    

    .devices-page__filtersNdevices{
        text-align: center;
        height:100%;

        .devices-page__searchbar{
            padding-bottom: 10px;
        }

        .devices-page__filtersNdevices__loader{
            width: 100%;
            margin-top:10em;
            height: 5em;
            > .MuiCircularProgress-root  {
                color: $color-green-even-darkest;
            }
        }
        .devices-page__filtersNdevices__device-wrapper{
            margin-bottom: 1em;
            [class^="col-"]{ 
                padding: 0px;
                padding-bottom:15px;
            }
        }
    }

    .device-list-actions{
        width: 100%;
    }
}

.fleetChangerSelectorDialog{
    display: grid;
    grid-template-rows: 1fr 1fr;
    width: 100%;

    .fleetChangerSelector{
        width: 100%;
    }

    .fleetChangerCheckbox{
        .justify-content: end;
    }
}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 375px) {  }

// Medium devices (tablets, 768px and up)
@media (min-width: 750px) {  }

// Large devices (desktops, 992px and up)
@media (min-width: 1125px) {
    .devices-page{
        grid-template-columns: minmax(450px, 1fr) 9fr;
        grid-template-rows: 1fr;
        
        .devices-page__filters__search {
            display: flex;
            position: relative;
            padding-top: 2em;
            padding-bottom: 2em;
            width: 25vw;

            input {
                height: 3em; padding-left: 1em; border-radius: 8px; 
                box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.11);
                &:focus { outline: none; }
            }            
            img {position: absolute; top: 2.5em; left: 23vw; width: 1.5vw}
        }

        .devices-page__titleNfilters{
            flex-direction: row;
            justify-content: flex-end;
              

            .devices-page__filters{
                height: 5em;
                display: flex;
                flex-direction: row;
                width: 65%; 
                
                > div { width: 20%; margin-left: 10px; }
                .devices-page__filters__search { 
                    width: 50%;
                    input {height: 50px; padding-left: 1em; }            
                }
            }
        }
        .devices-page__filtersNdevices{

            .color-filters {
                margin-left: 15px;
            }

            .devices-page__filtersNdevices__device-wrapper{
                [class^="col-"]{ 
                    padding: 15px;
                }

            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    .devices-page{
        .devices-page__titleNfilters{
            .devices-page__filters{
               width: 45%; 
               padding-right: 15px;
            }
            
        }
    }
 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {  }