@import "@lifepowr/components/src/colors.scss";

.device-device-device{
    max-width: 90%;
}

.single-device{
    background-color: $color-white;
    border-radius: 8px;
    height: 90px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.11);

    *[class^="dot"]{
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-bottom: 5px;
    }   
    .red{
        background-color: $color-red-normal;
        position: absolute;
        top: 0.95em;
        right: 0.95em;
    }
    .yellow{
        background-color: $color-yellow-light-main;
        position: absolute;
        top: 1.84em;
        right: 0.95em;
    }
    .green{
        position: relative;
        background-color: $color-green-darker;
        height: 40px;
        width: 40px;
    }

    .single-device__icon{
        width: 60px;
        position: relative;
        .single-device__icon__img {
            img { 
                width: 50%;
                height: 50%;
                position: absolute;
                top: 50%;
                left: 25%;
                transform: translateY(-50%);
            }
        } 
    }
    .single-device__info {
        width: 89%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .single-device__title {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: left;
            color: $color-green-even-darkest;
        }
        .single-device__id {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $color-green-even-darkest;
        }
    }
}