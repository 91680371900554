
@import '@lifepowr/components/src/colors.scss';

.header-component{
    max-height: 55px;  
    background-color: $color-white;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;

    .logo {
        height: 2em;
        margin-left: -2em;
    }
    
    .header-component__date-view{
        text-align: right;
        font-size: 0.6em;
        width: 100%;
    }
    .header-component__user-display{
        font-size: 0.9em;
        text-align: right;
        width: 100%;
    }
}


// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {  }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {  }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .header-component{
        max-height: 100px;
        padding-top: 15px;
        
        svg { 
            height: 4em; 
            margin-left: 0em;
        }

        .header-component__dateNuser {
            display: flex;
            padding-top: 1.5em;
            .header-component__date-view{
                font-size: 1em; //16px
                text-align: right;
            }
            .header-component__user-display{
                font-size: 1em; //16px
            }
        }
    } 
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {  }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {  }
