.dot-animation span,
.dot-animation-reverse span,
.dot-animation-stop span {
    font-size: 70px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}
.dot-animation span,
.dot-animation-reverse span{
    animation-name: blink;
}

.dot-animation > span {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.dot-animation-reverse > span {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.power-arrow {
    display: inline-block;
    margin-right: 0.1em;
    padding: 3px;
    border-bottom: 3px solid;
    border-left: 0 solid;
    border-right: 3px solid;
    border-top: 0 solid;
    height: 6px;
    width: 6px;
}

.dot {
    height: 6px;
    width: 6px;
    border-radius: 80%;
    display: inline-block;
    margin-right: 0.1em;
}

.power-arrow {
    /*height: max(0.75vh, 0.75vw);
    width: max(0.75vh, 0.75vw);*/
}
  
.dot-animation span:nth-child(2) {
    animation-delay: .1s;
}

.dot-animation span:nth-child(3) {
    animation-delay: .3s;
}

.dot-animation span:nth-child(4) {
    animation-delay: .4s;
}

.dot-animation span:nth-child(5) {
    animation-delay: .6s;
}

.dot-animation span:nth-child(6) {
    animation-delay: .8s;
}

.dot-animation span:nth-child(7) {
    animation-delay: .9s;
}

/* reverse animation when direction is < 0 */
.dot-animation-reverse span:nth-child(2) {
    animation-delay: .9s;
}

.dot-animation-reverse span:nth-child(3) {
    animation-delay: .8s;
}

.dot-animation-reverse span:nth-child(4) {
    animation-delay: .6s;
}

.dot-animation-reverse span:nth-child(5) {
    animation-delay: .4s;
}

.dot-animation-reverse span:nth-child(6) {
    animation-delay: .3s;
}

.dot-animation-reverse span:nth-child(7) {
    animation-delay: .1s;
}
  
@keyframes blink {
    0% {opacity: .2; }
    20% { opacity: .5; }
    30% { opacity: .7; }
    50% { opacity: .9; }
    100% { opacity: .2;}
}