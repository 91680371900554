@import '@lifepowr/components/src/colors.scss';

.io-tool-windows {
	z-index: 100000000;
	position: absolute;
	top: 0px;
	left: 0px;

	.window-title {
		display: flex;
		justify-content: space-between;
	}

	.window-wrapper {
		padding: 5px;
		width: 100%;
		height: 100%;
	}

	.window-rnd {
		min-height: 300px;
		top: 0px;
		left: 0px;
	}

}