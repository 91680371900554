@import "@lifepowr/components/src/colors.scss";

.user-display__name{
    color: $color-yellow-light-main;
    padding-right: 0.5em;
}

.user-display__chevron{
    svg {
        height: 1em;
    }
}

.user-display{
    position: relative;
    padding-right: 1em;
    z-index: 3;
    .user-display__menu {
        z-index: 2;
        width: 120%;
        height: 6em;
        position: absolute;
        top: 0;
        right: -1.05em;
        text-align: right;
        padding-right: 1em;
        padding-right: 2em;

        background-color: $color-white;
        -webkit-box-shadow: 0px 1px 5px 1px rgba(180,180,180,0.27); 
        box-shadow: 0px 1px 5px 1px rgba(180,180,180,0.27);
        
        .user-display__menu__logout{
            cursor: pointer;
        }
        .user-display__menu-chevron{
            cursor: pointer;
            margin-left: 3em;
            position: absolute;
            top:0;
            right: 1em;
            svg {
                height: 1em;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 512px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .user-display{
        justify-content: space-between;
        .user-display__menu {
            height: 5em;
            top: -2em;
            padding-left: 3em;
            width: 100%;
            padding-top: 1.7em;
            .user-display__menu-chevron{
                cursor: pointer;
                position: absolute;
                top: 1.8em;
                right: 0.9em;
                svg {
                    height: 1em;
                }
            }
        }
    }

    
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}