@import '@lifepowr/components/src/colors.scss';

.device-io-settings__form {
    flex: 1;
    display: flex;
    flex-direction: column;
	.schemaForm{
        flex: 1;
        display: flex;
        flex-direction: column;

        >.form-group {

            overflow: auto;
            min-height: 55vh;
            position: relative;
            flex: 1;

            >fieldset {
            	display: grid;
            	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            	grid-template-rows: minmax(1fr, 100px);
            	grid-gap: 15px;
                position: absolute;
                width: 100%;
                height: 100%;

                >p {
                    grid-column: 1/-1;
                }

                .checkbox {
                    display: flex;
                    flex-direction: column;
                }

            	.field-array {
            		grid-column: 1 / -1;
            	}

            	.field-object {
            		grid-column: 1 / -1;

    	        	>fieldset {
    	        		display: grid;
    		        	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    		        	grid-template-rows: minmax(1fr, 100px);
    		        	grid-gap: 15px;
    	        	}
    	        }
            }
        }

        button {
            border: unset;

            &:hover {
                color:$color-white;
                background-color: $color-green-darker;
            }
            &:focus {
                color: $color-yellow-light-main;
                background-color: $color-green-even-darkest;
            }

            &:disabled{
                color: $color-gray;
                background-color: $color-gray-light;
            }
        }
    }

    .device-io-settings__button-checkbox-wrapper{
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(75px, 170px));
        justify-content: end;
        align-content: end;
        .device-io-settings__check-box-wrapper{
            padding-left: 2em;
        }

        button {            
            font-size: 1em;
            height: 55px;
            border-radius: 8px;
            color:$color-white;
            background-color: $color-green-even-darkest;
            border: unset;

            &:hover {
                color:$color-white;
                background-color: $color-green-darker;
            }
            &:focus {
                color: $color-yellow-light-main;
                background-color: $color-green-even-darkest;
            }
        }
    }
}