$color-white: #fff;
$color-gray-lightest: #f8f8f8;//background
$color-gray-light: #f2f2f2;
$color-gray-normal: #e8e4e0; 
$color-gray: #c8c8c8;
$color-gray-dark: #b4b4b4;
$color-gray-darker: #002f2f;
$color-black: #001e1e;

$color-green-lighest:#F6FBFB;
$color-green-light:#D7EAEA;
$color-green-light-main:#ABD1D1;
$color-green-normal:#79b0b0;
$color-green-darker:#4c9292;
$color-green-even-darker:#207272;
$color-green-even-darkest:#0e5c5c;
$color-green-yellowish: #7c9e63;

$color-red-lighest:#ffbdbd;
$color-red-light:#ff9494;
$color-red-light-main:#e16161;
$color-red-normal:#c03a3a;
$color-red-darkest:#9c1b1b;

$color-yellow-lighest:#ffe0b1;
$color-yellow-light:#ffce86;
$color-yellow-light-main:#f9bc60;
$color-yellow-normal:#df9d39;
$color-yellow-darkest:#b9791a;