@import "@lifepowr/components/src/colors.scss";

.green-status{
    border-radius: 4px;
    .green-status__image {
        position: relative;
        text-align: center;
        img{
            width: 50%;
            padding-top: 1em;
            margin-left: -1em;
        }
    }
    .green-status__dropdown{
        padding-top: 2em;
        width: 100%;
        
        /*override default dropdown css for this component only*/
        .my-drop-down{                
            .Dropdown-root {

                .Dropdown-control {
                    padding: 14px 10px 8px 8px;
                    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.11);
                }
            }
        }
    }
    .green-status__statistics{
        align-items: center;
        padding-top: 1em;
    }
}


// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bo5otstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {  }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    .green-status {
        .green-status__image {
            img{
                width: 90%;
                margin: auto;
            }
        }
    } 
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .green-status {
        .green-status__image {
            img{
                width: 90%;
                margin: auto;
            }
        }
    }    
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    .green-status {
       
        .green-status__image {
            position: relative;
            text-align: center;
            img{
                width: 100%;
                padding-top: 8%;
                margin-left: -1em;
            }
        }
        .green-status__dropdown{
            margin-top: 0.5em; 
            margin-left: 16% ; 
            width: 80%;
            
        }
        .green-status__statistics{
            align-items: center;
            padding-top: 12%; //25% > 65px as design too large
        }
    }    
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {  }