@import "@lifepowr/components/src/colors.scss";

.color-filters{
    color: $color-white;
    display: flex;
    flex-direction: row;
    position: relative;
    > div { 
        margin: auto;
        width: 33%;
        height: 50px;
        border-radius: 8px;
        text-align: center;
        padding-right: 1em;
        cursor: pointer;
        .color-filters__icon img {
            width: 1em;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .color-filters__green {
        background-color: $color-green-darker;
        margin-right:1rem;
    }
    .color-filters__red {
        background-color: $color-red-light-main;
        margin-right:1rem;
    }
    .color-filters__yellow {
        background-color: $color-yellow-light-main;
    }
    .color-filters__grey {
        background-color: $color-gray-dark;
    }
    .color-filters__black {
        background-color: $color-gray-darker;
    }
    
}



// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 375px) {
    .color-filters {
        .color-filters {
            &__title{
                display: none;
            }
            &__total{
                display: none;
            }
            &__arrow{
                display: none;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 750px) {  }

// Large devices (desktops, 992px and up)
@media (min-width: 1125px) {
    .color-filters{
        height: 32em; 
        display: block;
        > div { 
            text-align: unset;
            .color-filters__icon img { position: unset; top: unset; transform: unset; display: block; }
        }    
        
        .color-filters {
            &__title{
                display: block;
            }
            &__total{
                display: block;
            }
            &__arrow{
                display: block;
            }
        }

        .color-filters__green {
            width: 11em;
            height: 290px;
            position: absolute;
            top: 0;
            left: 0px;
            
        }

        .color-filters__black {
            width: 11em;
            height: 290px;
            position: absolute;
            top: 0;
            left: 0px;
            
        }
    
        .color-filters__red {
            width: 9em;
            height: 190px;
            position: absolute;
            top: 0;
            left: 12em;
        }
        .color-filters__yellow {
            width: 12em;
            height: 190px;
            position: absolute;
            top: 14.5em;
            left: 12em;
        }
        
        .color-filters__grey {
            width: 12em;
            height: 190px;
            position: absolute;
            top: 14.5em;
            left: 12em;
        }
        
        .color-filters__title{
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $color-white;
        }
        .color-filters__date {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.06px;
            text-align: left;
        }
        .color-filters__icon img {
            width: 20px;
            margin-top:1.5em;
            position: unset;
        }
        .color-filters__total {
            font-size: 55px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.22px;
            text-align: left;
            color: $color-white;
        }
        .color-filters__arrow img {
            width: 29px;
            position: absolute;
            bottom: 15px;
            right: 15px;
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {  }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 

    .color-filters{
        height: 32em; 
        display: block;
        > div { 
            text-align: unset;
        }    
        
        .color-filters__green {
            width: 13.8em;
            height: 290px;
            position: absolute;
            top: 0;
            left: 0px;
            
        }

        .color-filters__black {
            width: 13.8em;
            height: 290px;
            position: absolute;
            top: 0;
            left: 0px;
            
        }

        .color-filters__red {
            width: 11.4em;
            height: 200px;
            position: absolute;
            top: 0;
            left: 15em;
        }
        .color-filters__yellow {
            width: 15em;
            height: 200px;
            position: absolute;
            top: 15.5em;
            left: 15em;
        }
        .color-filters__grey {
            width: 15em;
            height: 200px;
            position: absolute;
            top: 15.5em;
            left: 15em;
        }
    }
 }