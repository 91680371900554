@import "@lifepowr/components/src/colors.scss";

.one-line-statistics {
    display: flex;
    color: $color-white;
    height: 80px;
    align-items: center;
    justify-content: flex-start;

    .dark {
        color: $color-black;
    }

    .one-line-statistics__name {
        font-size: 0.8em;
        font-weight: bold;
    }

    .one-line-statistics__icon {

        // padding-right: 2em; 
        // padding-top:0.5em;
        img {
            width: 40px;
        }
    }

    .one-line-statistics__value-unit {
        font-size: 1.5em;
        font-weight: bold;
        margin-right: 0.3em;
    }

    .one-line-statistics__percentage-direction-yellow {
        font-size: 1em;
        font-weight: bold;
        color: $color-yellow-light-main;
    }

    .one-line-statistics__percentage-direction-blue {
        font-size: 1em;
        font-weight: bold;
        color: $color-green-light-main;
    }

    .one-line-statistics__percentage-direction-red {
        font-size: 1em;
        font-weight: bold;
        color: $color-red-normal;
    }

    .one-line-statistics__percentage-direction-green {
        font-size: 1em;
        font-weight: bold;
        color: $color-green-yellowish;
    }
}


// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .one-line-statistics {
        .one-line-statistics__icon {

            // padding-right: 0.3em; 
            img {
                width: 40px;
            }
        }

        .one-line-statistics__name {
            font-size: 0.9em;
        }

        .one-line-statistics__value-unit {
            font-size: 1.4em;
        }

        .one-line-statistics__percentage-direction-yellow,
        .one-line-statistics__percentage-direction-green,
        .one-line-statistics__percentage-direction-red,
        .one-line-statistics__percentage-direction-green {
            font-size: 0.9em;
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}