@import '@lifepowr/components/src/colors.scss';

.historical-plot-bars{
    background-color: $color-white;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 1em;

    position: relative;
    .green{
        color: $color-green-even-darkest;
    }
    .red {
        color: $color-red-light-main;
    }
    .historical-plot-bars__subtitle{
        font-size: 16px;
        font-weight: bold;
        text-align: left;
        color: $color-black;
    }
    .historical-plot-bars__text{
        font-size: 14px;
        font-weight: normal;
        text-align: left;
        color: $color-black;
    }

    .historical-plot-bars__bar-line-index{
        font-size: 1em;
        font-weight: bold;
        color: #b4b4b4;
        text-align: left;
        p{margin-bottom: 0;}
    }  
    
    /* graphs and it's divs*/
    .historical-plot-bars__bar-graphs{
        justify-content: center;
        > div { 
            width: 28%; 
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        /* top legend */
        .historical-plot-bars__bar-graphs-title {
            font-size: 16px;
            font-weight: bold;
        }
        .historical-plot-bars__bar-graphs-bar{
            .historical-plot-bars__bar-graphs-background{
                height:8em;
                background-color: #f8f8f8;
                width: 5em;
                position: relative;

                .historical-plot-bars__bar-graphs-line{
                    margin-left: -1em;
                    width: 7em;
                    height: 49%;
                    background-color: transparent;
                    border-bottom: 2px gray dashed;
                }
                .historical-plot-bars__bar-graphs-foreground{
                    position: absolute;
                    width: 5em;
                }
            }
        }
        .historical-plot-bars__bar-graphs-legend {

            /* bottom legend with values */
            .historical-plot-bars__bar-graphs-values{
                padding-left: 0.5em;
                width: 100%;
                font-size: 20px;
                font-weight: bold;
                text-align: left;
            }
            /* bottom legend units */
            .historical-plot-bars__bar-graphs-values-label{
                margin-left:0.5em;
                font-size: 12px;
                font-weight: bold;
                text-align: center;
                color: $color-gray-dark;
            }
        }
    }
    .historical-plot-bars__bar-zero{
        position: absolute;
        top:7em;
        left: 16em;
        font-size: 1em;
        font-weight: bold;
        color: #b4b4b4;
        
    }
    
}



// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
    
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {  

    .historical-plot-bars{
        .historical-plot-bars__bar-graphs{
            > div { 
                width: 27%; 
            }
            .historical-plot-bars__bar-graphs-bar{
                .historical-plot-bars__bar-graphs-background{
                    .historical-plot-bars__bar-graphs-line{
                        width: 12em;
                    }
                    .historical-plot-bars__bar-graphs-line-index{
                        width: 9%;
                    }   
                }
            }
        }
        .historical-plot-bars__bar-zero{
            left: 35em;
        }
        
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .historical-plot-bars{
        background-color: $color-white;
        padding: 43px;
        margin-bottom: 0em;
        .historical-plot-bars__bar-graphs{
            > div { 
                width: 27%; 
            }
            .historical-plot-bars__bar-graphs-bar{
                .historical-plot-bars__bar-graphs-background{
                    .historical-plot-bars__bar-graphs-line{
                        width: 7em;
                    }
                    .historical-plot-bars__bar-graphs-line-index{
                        width: 9%;
                    }   
                }
            }
        }
        .historical-plot-bars__bar-zero{
            left: 23em;            
        }
    }

}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
    .historical-plot-bars{
        .historical-plot-bars__bar-graphs{
            .historical-plot-bars__bar-graphs-bar{
                .historical-plot-bars__bar-graphs-background{
                    .historical-plot-bars__bar-graphs-line{
                        width: 9em;
                    }
                }
            }
        }
    }
 }
