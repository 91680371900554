@import '@lifepowr/components/src/colors.scss';

.status-element {
    display: block;

    .red{
        color: $color-red-light-main
    }
    .status-element__circle-wrapper{
        position:relative;

        .status-element__circle{

            .status-element__circle__label{

                position: absolute;
                top: 50%;
                transform: translateY(-45%);
                width: 100%; /* take the parent's width */

                /*.status-element__circle__label-power{
                    font-size: 12px;
                }*/
            }
        }   
        .status-element__circle-icon{
            height: 38px;
            width: 38px;
            border-radius: 50%;
            position: absolute;
            top: 0%;
            left: 80%;
            
            img {
                width: 75%;
                height: auto;
                position: relative;
                top: 45%;
                transform: translate(15%, -55%);
            }
        }
        .status-element__wifi{
            position: absolute;
            top:-10%;
            left: -10%;
        }
        .status_element__charge{
            position: absolute;
            top: 80%;
            left: 90%;
            font-weight: 700;
        }
    }

    .clickable {
        &:hover {
            background-color:#BBBBBB;
            border-radius: 25px
        }
    }
}
