@import "@lifepowr/components/src/colors.scss";

.yellow-status{
    padding:15px;
    display: flex;
    flex-direction: row;
    min-height: 5em;


    .yellow-status__info{  
        width: 50%; 
        padding: 1em;
        h2 {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $color-black;
        }
        p {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $color-black;
        }
    }
    .yellow-status__graph-wrapper {
        width: 50%;
        padding: 1em;
        position: relative;
        .yellow-status__labels{   
            width: 100%;
            color:$color-white;
            text-align: center;
            position: absolute;
            top:32%;
            right: 1%;

            .yellow-status__labels__value{
                font-size: 20px;
                font-weight: bold;
            }
            .yellow-status__labels__unit{
                font-size: 14px;
            }
        }    
    }
    .yellow-status__percentage{
        color:$color-white;
        font-weight: bold;
        text-align: center;
        font-size: 20px;
        position: absolute;
        bottom:0em;
        left: 0em;
    }
    .yellow-status__max{
        color:$color-white;
        font-weight: bold;
        text-align: center;
        font-size: 20px;
        position: absolute;
        bottom:0em;
        right: 0em;

    }
}


// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {  }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {  }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .yellow-status{
        flex-direction: column;
        padding: 5px;
        .yellow-status__info{  
            width: 100%;
            height: 8em;
            h2 {
                font-size: 16px;
            }
            p {
                font-size: 14px;
            }
            
        }
        .yellow-status__graph-wrapper {
            padding: 1em;
            width: 100%;
        }
        
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    .yellow-status{
        .yellow-status__info{
            height: 4.5em;
            h2 {
                font-size: 16px;
            }
            p {
                font-size: 14px;
            }
        }
        .yellow-status__graph-wrapper {
            padding: 2em;
            .yellow-status__labels{   
                .yellow-status__labels__value{
                    font-size: 30px;
                    font-weight: bold;
                }
                .yellow-status__labels__unit{
                    font-size: 20px;
                }
            }
        }
        .yellow-status__percentage{
            bottom:1em;
            left: 1em;
        }
        .yellow-status__max{
            bottom:1em;
            right: 0.5em;
    
        }
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {  }