/*@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/proxima_nova_bold-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/proxima_nova_reg-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}*/

/*Bullshit bootstrap thinking it knows best :@*/
html {
  font-size: 16px;
}

#root {
  height: 100%;

  >div {
    height: 100%;
  }

  .super-container {
    height: 90%;
  }

  .container {
    height: 70%;
  }

}

.lifepowr-link {
  color: blue;

  &:hover {
    text-decoration: underline;
    color: blue;
  }
}

body {
  margin: 0;
  overflow: hidden;
  max-height: 100vh;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Inter', Fallback, sans-serif;  
  font-style: normal;
  font-weight: 100;

  color:#001e1e;
  background-color: #f8f8f8;
}


$color-white: #fff;
$color-gray-lightest: #f8f8f8;//background
$color-gray-light: #f2f2f2;
$color-gray-normal: #e8e4e0; 
$color-gray: #c8c8c8;
$color-gray-dark: #b4b4b4;
$color-gray-darker: #002f2f;
$color-black: #001e1e;

$color-green-lighest:#F6FBFB;
$color-green-light:#D7EAEA;
$color-green-light-main:#ABD1D1;
$color-green-normal:#79b0b0;
$color-green-darker:#4c9292;
$color-green-even-darker:#207272;
$color-green-even-darkest:#0e5c5c;
$color-green-yellowish: #7c9e63;

$color-red-lighest:#ffbdbd;
$color-red-light:#ff9494;
$color-red-light-main:#e16161;
$color-red-normal:#c03a3a;
$color-red-darkest:#9c1b1b;

$color-yellow-lighest:#ffe0b1;
$color-yellow-light:#ffce86;
$color-yellow-light-main:#f9bc60;
$color-yellow-normal:#df9d39;
$color-yellow-darkest:#b9791a;

/*for large screens change default bootstrap container size */
@media (min-width: 1024px){
  .container { max-width: 1540px; }
}

.button { 
  font-size: 1em;
  width: 200px;
  height: 55px;
  border-radius: 8px;
  color:$color-white;
  background-color: $color-green-even-darkest;
  border: unset;

  &:hover {
    color:$color-white;
    background-color: $color-green-darker;
  }
  &:focus {
    color: $color-yellow-light-main;
    background-color: $color-green-even-darkest;
  }

  &:disabled{
    color: $color-gray;
    background-color: $color-gray-light;
  }
}


// Why is this defined here?!
input {
  width: 100%;
  height: 45px;
  background-color: $color-white;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  border: none;

  &::placeholder {
    color: $color-gray;
  } 
}
input-label {
  color: $color-black;
}

a {
  text-decoration: none;
  color:$color-white;

  &:hover{
    cursor: pointer;
    color:$color-white;
  }
}

/* REMOVE SPINNERS ON CHROME */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* REMOVE SPINNERS ON Firefox */
input[type=number] {
  -moz-appearance: textfield;
}