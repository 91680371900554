.chart {
    position: relative;
    min-height: 30em;
    min-width: 35em;
    display: grid;
    grid-template-rows: fit-content(50px) 1fr;
    height: 100%;

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: "#fff";
        z-index: 10;
        position: absolute;
    }
}
.modebar {
    left:0em;
}
