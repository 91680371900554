@import '@lifepowr/components/src/colors.scss';

.diagnostics-container{
	overflow: auto;

	.device-diagnostics-accordion {
		display: grid;
		grid-template-columns: 1fr 1fr 25px;
		width: 100%;

		.device-diagnostics-accordion-details-summary{
			text-align: end;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.device-diagnostics-accordion-status-good{
			justify-self: end;
			color: green;
		}

		.device-diagnostics-accordion-status-bad{
			justify-self: end;
			color: red;
		}
	}
}

.diagnosis-details {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-template-rows: 1fr 5fr 1fr;
	grid-template-areas:
		"message directionF"
		"message logs"
		"display directionB";

	.diagnosis-display {
		grid-area: display;
		justify-self: center;
		align-self: center
	}

	.diagnosis-message {
		grid-area: message;
		justify-self: center;
		align-self: center
	}

	.diagnosis-logs-controls {
		grid-area: controls;

		.range-button{
			min-width: 35px;
		}
	}

	.diagnosis-direction-forward {
		grid-area: directionF;
	}

	.diagnosis-direction-back {
		grid-area: directionB;
	}

	.diagnosis-logs {
		grid-area: logs;
		max-height: 250px;
		overflow: auto;

		.log-message {
			display: flex;

			.timestamp {
				flex: 2;
			}

			.message {
				flex: 2;
			}
		}

		.message-details {
			white-space: pre-wrap;
		}

	}
}

.replace-button {
	justify-self: end;
}
