.ev-charger-brand{
	//max-height: 200px;
	max-width: 150px;

	.ev-charger-brand-media{
		height: 90%;
		width: 100%;
	}
}

.ev-brand-cards{
	display:  grid;
	grid : auto / repeat(auto-fit, minmax(50px, 150px));
	/*grid-template-columns: repeat(3, 1fr);*/
	justify-content : center;
	grid-gap : 20px;
	padding : 20px;
    overflow: auto;
}

.ev-brand-wrapper{
	padding: 10px;
	display: grid;
	grid-template-rows: fit-content(50px) fit-content(50px) 1fr minmax(100px, 250px) fit-content(50px);

	.ev-brand-wrapper-error{
		color: #721c24;
		background-color: #f8d7da;
		border-radius: 0.25rem;
		justify-self: center;
		max-width: 500px;
	}

	.ev-brand-wrapper-success{
		background-color: #d4edda;
		color: #155724;
		border-radius: 0.25rem;
		justify-self: center;
		max-width: 500px;
	}
}

.ev-comms-icon-media{
	min-width: 100px;
	min-height: 100px;
}

.ev-title{
	// display: flex;
}

.ev-strategy{
	padding: 10px;

	.scheduler-control{
		display:grid;
		grid-template-rows: 1fr 1fr 1fr;
		justify-content: center;

		.scheduler-control-mode {
			display: flex;
			justify-content: space-around;
		}
	}

	.force-ev-charge{
		display: flex;
		justify-content:right;
	}
}

.ev-chargers-list{
	display:grid;
	justify-content: center;
	grid : auto / repeat(auto-fit, minmax(50px, 150px));
	grid-gap: 10px;
}

.ev-charger-info{
	display: grid;
	.ev-charger-enable{
		justify-self: start;
	}
	.ev-charger-info-box{
		justify-self: center;
	}
}

.ev-charger-tabs{
	.MuiTabs-flexContainer{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(50px, 100px));
		justify-content: center;
	}

	.MuiTab-root{
		min-width: 25px;
	}
}

.ev-charger-slider-elements-div {
	display: grid;
	grid-template-columns: 2fr 3fr 1fr;
}

.ev-charger-details{
	.MuiAccordionDetails-root{
		display: grid;
		grid-template-columns: 60px 1fr;
		justify-items: center;
		align-items: center;
	}

	.ev-charger-accordion-image{
		max-width: 75px;
		max-height: 75px;
	}
}

.ev-component-simple-strategy{
	padding-top: 15px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(auto-fill, minmax(1fr, 500px));
	grid-gap: 25px;
}

.ev-component-display-none{
	display: none;
}

.occp-charger-list{
	display: grid;
	grid-gap: 10px;
	padding:10px;
	justify-content: center;
	grid-template-columns: repeat(auto-fit, minmax(120px, 150px));
	min-height: 200px;

	.ocpp-charger-card {
		height: 100%;
		width: 100%;
		
		.ocpp-charger-card-icon {
			height: 80%;
			width: 100%;
		}
	}

}

.ocpp-cp{
	padding: 10px;
	min-height: 300px;
	
	.ocpp-brand-steps {
		display: grid;
		grid-template-rows: fit-content(100px) 3fr;
		grid-gap: 10px;
	}
}

.ocpp-cp-charging-details {
	display: grid;
	grid-template-columns: 60px 1fr;
	justify-items: center;
	align-items: center;
	padding: 10px;

	.ocpp-cp-charging-strategy {
		padding: 10px;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(200px, 300px));
		grid-gap: 10px;
	}

}

.ocpp-error {
	color: #721c24;
	background-color: #f8d7da;
	border-radius: 0.25rem;
	justify-self: center;
	max-width: 500px;
}

.ocpp-conn-icon {
	.good {
		color: #155724;
	}
	.warn {
		color: #ffc107;
	}
	.error {
		color: red;
	}
	
	position: absolute;
}

.ev-charger-diagnostic-accordion {
	display: grid;
	grid-template-columns: 1fr fit-content(100px) 25px;
	width: 100%;
	grid-gap: 5px;

	.ev-charger-diagnostic-summary{
		text-align: end;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.ev-charger-diagnostic-accordion-status-good{
		justify-self: end;
		color: green;
	}

	.ev-charger-diagnostic-accordion-status-bad{
		justify-self: end;
		color: red;
	}
}