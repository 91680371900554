@import "@lifepowr/components/src/colors.scss";

.fleet-page {
    overflow: auto;
    padding-top: 15px;
    height: 100%;
    background-color: $color-gray-lightest;
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        &__welcome {
            padding-top: 54px;
            font-size: 34px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $color-black;
            margin-bottom: 15px;
        }
        &__dropdown {
            display: flex;
            align-items: flex-end;
            .my-drop-down {
                width: 100%;
            }
        }
    }

    &__components-wrapper {
        padding-left: 15px;
        padding-right: 15px;

        height: 100%;

        &__green-component {
            background-color: $color-green-darker;
            border-radius: 4px;
            grid-row-end: span 15;
        }

        &__plot-bars,
        &__plot-lines {
            border-radius: 4px;
            background-color: $color-white;
            grid-row-end: span 15;
        }
    }
}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .fleet-page {
        &__header {
            &__dropdown {
                .my-drop-down {
                    min-width: 15rem;
                }
            }
        }
    }
}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .fleet-page {
        background-color: $color-green-lighest;

        &__components-wrapper {
            padding-left: 15px;
            padding-right: 15px;

            height: 100%;
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: auto;
            grid-column-gap: 18px;
            grid-row-gap: 18px;

            &__green-component {
                background-color: $color-green-darker;
                border-radius: 4px;
                grid-row-end: span 15;
            }

            &__plot-bars,
            &__plot-lines {
                border-radius: 4px;
                background-color: $color-white;
                grid-row-end: span 15;
            }

            &__yellow-component-wrapper{
                padding-right: 7.5px;
                padding-left: 7.5px;
            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
