@import '@lifepowr/components/src/colors.scss';

.house-composition{
    .house-composition-wrapper{
        width: 100%;
        height: 50vh;
        .house-composition__house-wrapper{
            width: 50%;
            height: 17em;
            position: relative;
            left: 5%;

            .house-composition__house-circle{
                width: 100%;
                .house-composition-house__circles{
                    width: 11em;
                    position: absolute;
                    top:50%;
                }
                .house-composition-house__house{
                    width: 9em;
                    position: absolute;
                    top:55%;
                    left:15%;
                }
            }
            .house-composition__status{

                .house-composition__residence{
                    width: 90px;
                    position: absolute;
                    top:20%;
                }
            }
        }
        .house-composition__status-wrapper{
            width: 50%;
            height: 15em;  
            position: relative;
            top: 10%;
            
            > div {
                width: 100%;
                display: flex;
                flex-direction: row;
            }         
            > div > div { //ellipssis
                width: 50%; //force size to prevent absolute position from removing object space
            }
            .house-composition__pv{
                width: 100%;
                height: 5em;
                position: absolute;
                left: 0;
                top:0%;
                .house-composition__pv__ellipsis {
                    div{
                        position: absolute;
                        top:98%;
                        left:0%;
                        transform: rotate(-40deg);
                        -ms-transform: rotate(-40deg); /* IE 9 */
                    }
                }
                .house-composition__pv__element{
                    margin-top: 0em;
                    margin-left: -2.2em;
                }
            }
            .house-composition__grid{
                width: 100%;
                height: 5em;
                position: absolute;
                left: 0;
                top:40%;
                .house-composition__grid__ellipsis {
                    div{
                        position: absolute;
                        top:50%;
                        left:15%;
                        transform: rotate(-10deg);
                        -ms-transform: rotate(-10deg); /* IE 9 */
                    }
                }
            }
            .house-composition__ev{
                width: 100%;
                height: 5em;
                position: absolute;
                left: 0;
                top:74%;
                .house-composition__ev__ellipsis {
                    div{
                        position: absolute;
                        bottom:45%;
                        left:12%;
                        transform: rotate(15deg);
                        -ms-transform: rotate(15deg); /* IE 9 */
                    }
                }
                .house-composition__ev__element{
                    margin-top: 1em;
                }

            }
            .house-composition__battery{
                width: 100%;
                height: 5em;
                position: absolute;
                right: 1em;
                top:105%;

                .house-composition__battery__ellipsis {
                    div{
                        position: absolute;
                        bottom:70%;
                        left:0%;
                        transform: rotate(45deg);
                        -ms-transform: rotate(45deg); /* IE 9 */
                    }
                }
                .house-composition__battery__element{
                    margin-top: 2.5em;
                    margin-left: -2.2em;
                }
            }
            
            .house-composition__pv__element,
            .house-composition__grid__element,
            .house-composition__ev__element,
            .house-composition__battery__element{
                position:relative;
                width: 80px; 
                height: 5.5em;   
                .house-composition__battery__charge{
                    width: 90px;
                    position: absolute;
                    bottom: 0em;
                    left: 4.5em;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0.06px;
                    color: $color-green-darker;
                }
            }

            .house-composition__button{
                cursor: pointer;
            }
        }
    }
}

.house-composition-ems-version{
    font-size: 12px;
}

.device-page__console{
    position: relative;
    top: -15%;
    left: 25px
}

// Medium devices (tablets, 512px and up)
@media (min-width: 512px) {
    .house-composition{
        .house-composition-wrapper{
            padding-left: 4em;
            .house-composition__house-wrapper{
                .house-composition__status{
                    .house-composition__residence{
                        width: 110px;
                        position: absolute;
                        top:12%;
                        left: -12%;
                    }
                }
            }
        }
        .house-composition__status-wrapper{
            margin-left: -1em;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .house-composition{
        .house-composition-wrapper{
            .house-composition__house-wrapper{
                top: 10%;
                width: 40%;
                padding-left: 2em;
                .house-composition__house-circle{
                    .house-composition-house__circles{
                        width: 17em;
                        position: absolute;
                        top:30%;
                    }
                    .house-composition-house__house{
                        width: 14em;
                        position: absolute;
                        top:35%;
                        left:4em;
                    }
                }
                .house-composition__status{
                    .house-composition__residence{
                        width: 120px;
                        position: absolute;
                        top:0%;
                        left:-10%
                    }
                }
            }
            .house-composition__status-wrapper{
                top: 10%;
                width: 60%;
                height: 20em;  
                margin-top:0em;
                margin-left: 0em;
                
                .house-composition__pv__element,
                .house-composition__grid__element,
                .house-composition__ev__element,
                .house-composition__battery__element{
                    width: 100px;    
                    
                    .house-composition__battery__charge{
                        top: 5em;
                        left: 5.5em;
                    }
                }
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .house-composition{
        .house-composition-wrapper{
            padding-left: 0em;
            .house-composition__house-wrapper{
                width: 50%;

                .house-composition__house-circle{
                    .house-composition-house__circles{
                        width: 10em;
                        position: absolute;
                        top:50%;
                        left:0;
                    }
                    .house-composition-house__house{
                        width: 9em;
                        position: absolute;
                        top:50%;
                        left:10%;
                    }
                }
                .house-composition__status{
                    .house-composition__residence{
                        width: 90px;
                        position: absolute;
                        top:15%;
                        left:1em;
                    }
                }
            }
            .house-composition__status-wrapper{
                width: 50%;
                height: 20em;  
                margin-top:0em;
                margin-left: 0em;
                
                .house-composition__pv__element,
                .house-composition__ev__element,
                .house-composition__grid__element,
                .house-composition__battery__element{
                    width: 80px; 

                    .house-composition__battery__charge{
                        top: 4em;
                        left: 5.5em;
                    }
                }
            }
            .house-composition__status-wrapper{
                margin-left: 0em;
            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 

    .house-composition{
        .house-composition-wrapper{
            .house-composition__house-wrapper{
                width: 50%;
                top: -10%;
                .house-composition__house-circle{
                    .house-composition-house__circles{
                        width: 12em;
                        position: absolute;
                        top:50%;
                    }
                    .house-composition-house__house{
                        width: 10.5em;
                        position: absolute;
                        top:50%;
                        left:15%;
                    }
                }
                .house-composition__status{
                    .house-composition__residence{
                        width: 100px;
                        position: absolute;
                        top:15%;
                    }
                }
            }
            .house-composition__status-wrapper{
                top: -5%;
                width: 50%;
                height: 20em;  
                margin-top:0em;
                
                .house-composition__pv__element,
                .house-composition__grid__element,
                .house-composition__ev__element,
                .house-composition__battery__element{
                    width: 90px;    
                }
            }
        }
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
    .house-composition{
        .house-composition-wrapper{
            .house-composition__house-wrapper{
                left: 12%;
                top: 10%;
                .house-composition__house-circle{
                    .house-composition-house__circles{
                        width: 14em;
                        position: absolute;
                        top:50%;
                    }
                    .house-composition-house__house{
                        width: 13em;
                        position: absolute;
                        top:55%;
                        left:10%;
                    }
                }
                .house-composition__status{
                    .house-composition__residence{
                        width: 110px;
                        position: absolute;
                        top:20%;
                    }
                }
            }
            .house-composition__status-wrapper{
                top: 15%;
                .house-composition__pv{
                    .house-composition__pv__element{
                        margin-top: -1.4em;
                    }
                }
                .house-composition__pv__element,
                .house-composition__grid__element,
                .house-composition__ev__element,
                .house-composition__battery__element{
                    width: 95px;    
                    margin-left: 2em;
                    left: -10%;
                }
            }
        }
    }
}