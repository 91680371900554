@import "@lifepowr/components/src/colors.scss";

.eanPic {
    max-width: 100%;
}

.device-page {
    background-color: $color-gray-lightest;
    padding-left: 15px;
    padding-right: 15px;
    display:grid;
    height: 100%;
    overflow: auto;

    &__left {
        background-color: $color-white;
        height: 100%;
        min-height: 80vh;

        .device-page__title {
            margin-top: 0px;
            font-weight: bold;
            text-align: left;
            color: $color-black;
            padding: 5px;
        }

        .composition_wrapper {
            display:grid;
            width: 100%;
            height: 100%;
            grid-template-rows: fit-content(100px) 1fr fit-content(40px);
        }
    }

    &__right {
        background-color: $color-gray-lightest;
        min-height: 80vh;
        overflow-x: auto;

        .device-body {
            display: grid;
        }

        .device-nav-menu {
            margin-right: 0px;
            margin-top: 10px;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            justify-content: center;
            a {
                width: 100%;
                display: block;
                font-size: 18px;
                line-height: normal;
            }
        }
    }
}

.requestSuccess{
    background-color: #d4edda;
    color: #155724;
    border-radius: 0.25rem;
}

.requestError{
    color: #721c24;
    background-color: #f8d7da;
    border-radius: 0.25rem;
}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 1000px) {
    .device-page {
        
        // grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        overflow: visible;

        &__right {
            display: grid;
            grid-template-rows: fit-content(100px) 9fr;
            min-height: 0px;

            
            .device-body {
                overflow: auto;
            }

        }


        /*&__left {
            min-height: 80vh;
        }*/
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .device-page {
        
        grid-template-columns: 1fr 2fr;

        &__right {
            min-height: 0px;
            .device-nav-menu {
                margin-top: 10px;
            }
        }


        &__left {
            min-height: 0px;
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1500px) {
    .device-page {
        grid-template-columns: 2fr 4fr;
    }
    /*.device-page {
        &__right {
            grid-column: span 2;
            .device-nav-menu {
                a {
                    font-size: 20px;
                }
            }
        }

        &__left {
            grid-column: span 1;
        }

        &__console {
            top: 40em;
            left: 1em;
            
        }
    }*/
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {

}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1880px) {
}

.address-container {
    .MuiFormControl-root {
        .MuiGrid-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            
            // streetName
            .MuiGrid-root:nth-child(1) {
                grid-column: 1/3;
            };
            
            // houseNumber
            .MuiGrid-root:nth-child(3) {
                grid-column: 1/4;
            };
            
            // postCode
            .MuiGrid-root:nth-child(4) {
                grid-column: 1/2;
            };

            // city
            .MuiGrid-root:nth-child(5) {
                grid-column: 2/4;
            };

            // state
            .MuiGrid-root:nth-child(6) {
                grid-column: 1/3;
            };

            // country
            .MuiGrid-root:nth-child(7) {
                grid-column: 3/4;
            };
        }
    }

    &.dense {
        padding-top: 20px;
        .MuiGrid-root {
            padding-top: 0 !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            @media (max-width: 576px) {
                grid-column: 1/4 !important;
            }            
        }
    }
}