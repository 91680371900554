@import '@lifepowr/components/src/colors.scss';

.nav-bar-option {

    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    margin-right: 20px;
    color: $color-white;
    position: relative;

    .nav-bar-option__icon{
        width: 30px;
        object-fit: contain;
        img{ width: 75%;}
    }
    .nav-bar-option__name {
        padding-top:0.1em;
    }
    .nav-bar-option__triangle{
        width: 0; 
        height: 0; 
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid $color-white;
        position: absolute;
        bottom:0;
        left:3em;
    }
}


// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {  }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .nav-bar-option {
        margin-right: 60px;
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {  }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {  }