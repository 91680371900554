@import "@lifepowr/components/src/colors.scss";

.historical-plot-fleet {
    background-color: $color-white;
    padding: 15px;
    margin-bottom:1em;
    border-radius: 4px;
    min-height: 20em;
    &__header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 2rem;
        h1 {
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $color-black;
        }
        .my-drop-down{
            height: 60px;
            width: 175px;
            border-radius: 8px;
            padding-top: 0.5em;
        }
    }
    &__graph {
        overflow-x: scroll;
    }
}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .historical-plot-fleet {
        padding: 43px;
        margin-bottom:0em;
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
