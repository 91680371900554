@import '@lifepowr/components/src/colors.scss';

.device-details{
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    height:100%;
    overflow: auto;

    h2{
        font-size: 16px;
        font-weight: bold;
        color: $color-black;
    }

    .balancing-form {
        #submit-button {
        }
        flex: 1;
        overflow: auto;
    }

    .device-details-form-wrapper{
        flex: 1;
        overflow: auto;
    }

    .device-details__block{
        border-radius: 8px;
        background-color: $color-white;
        margin-bottom: 38px;
    }   
    .device-details__house-img{
        margin-top: 1.67em;
        border-radius: 8px;
        background-color: $color-white;
        img {
            height: 100%;
            width: 100%;
        }
    }
    [class^="col-"]{ 
        padding-left : 0px;
    }
    .configurationForm{
        overflow-y: auto;
        overflow-x: hidden;
    }
    .requestSuccess{
        background-color: #d4edda;
        color: #155724;
        border-radius: 0.25rem;
    }

    .device-details-form-title{
        display: grid;
        grid-template-columns: 1fr;
        
        .device-details-form-title-buttons{
            justify-self: end;
        }
    }
}


.configurationFormDisabled{
    #root_settings_converters_0_battery__anyof_select{
        pointer-events: none;
    }
    #root_configuration_converter__oneof_select{
        pointer-events: none;
    }
    #root_settings_tariffStructure__oneof_select{
        pointer-events: none;
    }
    #root_settings_tariffStructure__anyof_select{
        pointer-events: none;
    }
}

.schemaFormConfiguration{
    max-height: 90%;
    overflow-y:auto;
    padding: 10px;

    .MuiGrid-grid-xs-12:nth-child(3){
        .panel-body {
            .MuiBox-root:nth-child(1) {
                display: none;
            }
        }
    }

    .MuiBox-root-51 {
        display: none;
    }

    .btn-info {
        display: none;
    }
}

.requestError{
    color: #721c24;
    background-color: #f8d7da;
    border-radius: 0.25rem;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .device-details{
        .configurationForm{
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .device-details{
        .configurationForm{
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}
