/* defaults */
.device-container{
	display: grid;
	grid-template: repeat(auto-fill, minmax(0, 58px)) / repeat(12, 1fr);
	grid-gap: 0.5vh;
	height:100%;

	.header {
		grid-column: 1 / -1;
		grid-row: 1 / span 1;
	}

	.navigation {
		grid-column: 1 / -1;
		grid-row: 2 / span 1;
	}

	.content {
		grid-column: 1 / -1;
		grid-row: 3 / -1;
		position: relative;

		.to-overflow {
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			overflow: auto;
			height: 100%;
		}
	}

	.footer {
		grid-column: 1 / -1;
		grid-row: -1 / span 1;
		justify-self: center;
		align-self: center;
	}
}

.device-container-full {
	height: 100%;
	width: 100%;
}
