@import "@lifepowr/components/src/colors.scss";

.nav-bar {
    max-height: 55px;
    line-height: 55px;
    white-space: nowrap;

    background-color: #0e5c5c;
    color: #fff;
   
    .nav-bar__wrapper {
        .nav-bar__wrapper__options {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 10em;

            .nav-bar__wrapper__option{
                width: 50%;
            }    
        }
    }
}


// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {  }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .nav-bar {
        .nav-bar__wrapper {
            .nav-bar__wrapper__options {
                justify-content: start;
                .nav-bar__wrapper__option{
                    width: 30%;
                }    
            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    .nav-bar {
        .nav-bar__wrapper {
            .nav-bar__wrapper__options {
                justify-content: start;
                .nav-bar__wrapper__option{
                    width: 25%;
                }    
            }
        }
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {  }

