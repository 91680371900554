@import "@lifepowr/components/src/colors.scss";

.status-yellow-group{
    > .col-lg-4{
    padding-right: 0px;
    padding-left: 0px;
        
    }
    
    .status_yellow_group__component {
        border-radius: 4px;
        background-color: $color-yellow-light-main;
    }
}


// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {  }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    .status-yellow-group{
        > :nth-child(1){
            padding-right: 7.5px;
        }
        > :nth-child(2){
            padding-left: 7.5px;
        }
        > :nth-child(3){
            padding-right: 7.5px;
        }
    }    
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .status-yellow-group{
        .col-lg-4{
            padding-right: 7.5px;
            padding-left: 7.5px;
        }
    }    
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {  }
