@import '@lifepowr/components/src/colors.scss';

.circular-progress-loader{
    // width: 100%;
    margin: auto;
    // height: 100%;
    text-align: center;
    .MuiCircularProgress-root {
        color: $color-green-even-darkest;
    }
}