.login-checkbox {
	color: blue;
	display: inline-block;
}

.login-checkbox:hover {
	text-decoration: underline;
	color: blue;
}

.login-checkbox:visited {
	color: purple;
}

.loginFormHidden {
	visibility: hidden;
}

.loginForm {
	overflow: auto;
}